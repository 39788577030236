module.exports = {
  siteTitle: 'Shikhar Subedi Resume', // <title>
  manifestName: 'Resume',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  firstName: 'Shikhar',
  lastName: 'Subedi',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/shikharsubedi/',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/subedishikhar/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/ssubedi24',
    },
  ],
  email: 'merocastle@gmail.com',
  phone: '+1-425-435-5973',
  address: 'Sunnyvale, CA 94086, USA',
};
