import React from 'react';

import Layout from '../components/Layout';

// import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName}
            <span className="text-primary">{config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address} · {config.phone} ·
            <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
            I am an experienced developer passionate about programming. I have been involved in building web applications
            professionally since 2011. I am a generalist with skills in both frontend and backend technology stacks.
            I worked with PHP and MySQL when I started out, dabbling in javascript, HTML and CSS for UI. I switched to Node.js
            for backend development in 2017.
          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url} target="_blank">
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer Node.js microservices</h3>
              <div className="subheading mb-3">B-stock Solutions</div>
              <p>
                Create Node.js microservices that expose REST apis. 
                Responsible for unit and integration tests and Continuous Integration using Gitlab-ci.
                Technologies used: Node.js, Mongodb, RabbitMQ, PHP, MySQL, HTML, CSS, Jquery
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">April 2019 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Senior Software Engineer</h3>
              <div className="subheading mb-3">Freedom Financial Network</div>
              <p>
                Freedom Financial Network provides credit-card debt consolidation services to its clients.
                I was responsible for creating REST apis using Symfony 3 framework in PHP and MySQL as storage.
                I wrote unit tests using PHPUnit. From 2017, I started creating REST apis in node.js supporting
                a rebuild of the legacy system.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">May 2016 - April 2019</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer - Contract</h3>
              <div className="subheading mb-3">Surveymonkey wufoo</div>
              <p>
                Wufoo is a platform that provides its users with automated forms for surveys and payments.
                I was heavily involved in security improvements like SQL injects prevention, XX filtering and CSRF protection
                This was a legacy project which did not use any framework and had security issues.
                I was also involved in feature development like coupon management.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">May 2015 - April 2016</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer</h3>
              <div className="subheading mb-3">Adition Tecnologies, Germany</div>
              <p>
                Adition Technologies is a programmatic advertising technology company. I worked in
                the middleware department developing features in PHP and MySQL. I was responsible for unit
                and integration tests in PHPUnit.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Feb 2014 - Dec 2014</span>
            </div>
          </div>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer</h3>
              <div className="subheading mb-3">F1soft International - Kathmandu, Nepal</div>
              <p>
                I worked in a money transfer web application where I was responsible for api integrations with
                Banks and other institutions. I created REST and SOAP clients as well as servers.
                Technologies used were PHP, MySQL, Doctrine 2, Code Igniter Framework, Silex framework
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">May 2011 - Dec 2013</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Tribhuvan University</h3>
              <div className="subheading mb-3">Bachelor's Degree</div>
              <div>Computer Engineering</div>
              
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2001-2006</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="skills"
      >
        <div className="w-100">
          <h2 className="mb-5">Skills</h2>

          <div className="subheading mb-3">
            Programming Languages &amp; Tools
          </div>
          <ul className="list-inline dev-icons">
            <li className="list-inline-item">
              <i className="fab fa-html5"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-css3-alt"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-js-square"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-node-js"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-npm"></i>
            </li>
          </ul>

          <div className="subheading mb-3">Workflow</div>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-check"></i>
              Backend Development using Node.js, Express and Mongodb
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Unit Testing using Mocha, Chai, Sinon
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Front-end development using HTML, CSS and Javascript
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Agile Development &amp; Scrum
            </li>
          </ul>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="interests"
      >
        <div className="w-100">
          <h2 className="mb-5">Interests</h2>
          <p>
            Apart from being a web developer, I enjoy playing basketball, tennis and table-tennis.
            I played table-tennis competitively at a national level in Nepal during my school days.
          </p>
          <p className="mb-0">
            I am a life-long-learner and have interest in learning more about developmental economics
            and finance. Right now I am going through Khan Academy's capital and financial markets curriculum
             and enjoying it thoroughly.I also enjoy food and love eating and travelling. 
             For entertainment, I watch food videos on youtube and listen to neo-soul music.
          </p>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="awards"
      >
        <div className="w-100">
          <h2 className="mb-5">Awards &amp; Certifications</h2>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              <a href="https://www.coursera.org/account/accomplishments/verify/UPTH8E77J7X5" target="_blank">
                Google Cloud Platform Fundamentals: Core Infrastructure
              </a>           
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              <a href="https://www.coursera.org/account/accomplishments/verify/NKRDLK5EZCY8" target="_blank">
                Google Cloud Platform Fundamentals: Getting Started With Application Development
              </a>
            </li> 
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
